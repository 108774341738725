.linkLogo{
    text-decoration: none;
    text-align: center;
    color: var(--mid-color);
}
.outerLinkLogo{
    display: flex;
    justify-content: space-evenly;
    margin: auto;
    align-items: flex-end;
}


.connectHeading{
    margin-top: 40px;
    list-style: none;
    text-align: center;
}
.connectHeading button{
   font-size: 17px;
   border: 2px soild var(--mid-color);
   border-radius: 7px;
   padding: 2px;
   padding-left: 7px;
   padding-right: 7px;
   margin: 10px;
   font-weight: bold;
   color: var(--top-color);
   background-color: var(--mid-light);
}