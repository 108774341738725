.skillHeading {
    display: flex;
    justify-content: center;
    font-size: 30px;
}

.skillLogo {
    display: flex;
    justify-content: space-evenly;
    margin: 10px;
    margin-bottom: 10px;
    align-items: flex-end;
    padding: 10px;
    background-color: var(--top-light);
    width: 100px;
    height: 100px;
    border-radius: 100%;
}
