* {
    margin: 0;
    padding: 0;
}

.landingPageContent {
    width: 100%;
    flex-grow: 1;
    padding-top: 130px;
    /* background-color: rgb(32, 32, 32); */
}

.intro {
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    padding-left: 5vw;
}

.introProfession {
    font-size: 3.2rem;
    font-weight: 1000;
    line-height: normal;
}

.myimage {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center
}

.myimage>img {
    filter: drop-shadow(0px -0px 10px var(--mid-color));
    border-bottom: 5px solid var(--mid-color);
}

.viewProjects {
    height: 35px;
    width: 180px;
    background-color: transparent;
    border: 2px solid var(--mid-color);
    border-radius: 10px;
    margin-top: 15px;
    color: var(--mid-color);
    font-weight: 500;
}


@media screen and (max-width: 768px) {
    .landingPage{
        margin-top: 100px;
        overflow: hidden;
    }
    .myimage>img {
        opacity: .4;
    }

    .myimage {
        z-index: 1;
    }

    .outerIntroBox {
        z-index: 2;
        line-height: 18px;
        color: var(--mid-color);
        padding: 20px 10px 0 0;
    }

    .outerImageBox {
        position: absolute;
        top: 100px;
        right: 0;
        overflow: hidden;
        padding: 20px;
    }

    .introProfession {
        line-height: 50px !important;
    }
}
