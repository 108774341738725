.smallProjectBox{
    padding: 10px;
    max-height: 450px;
}
.imageBox{
    height: 300px;
    width: 450px;
    border: 2px solid rgb(132, 132, 132);
    border-radius: 20px;
    filter: drop-shadow(0px -0px 10px #bcbcbc);
}

.tech>button{
    padding: 2px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 7px;
    margin-left: 10px;
    margin-top: 2px;
    color: var(--top-color);
    background-color: var(--mid-light);
}

@media screen and (max-width: 768px) {

    .imageBox{
        height: 70vw;
        width: calc(100vw - 5px)

    }
}