.header {
    width: 100%;
    position: fixed;
    height: 100px;
    z-index: 10;
    font-weight: bolder;
    font-size: 20px;
    background-color: var(--top-color);
    filter: drop-shadow(0px 0px 20px var(--bootom-color));
}

.headerRoutes {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: flex-end;
    height: 100%;
    list-style: none;
    align-items: center;
}

.headerRoutes>li {
    margin-right: 40px;
    text-align: center;
}

.headerRoutes a {
    text-decoration: none;
    color: var(--mid-color);
}

@media screen and (max-width: 768px) {
    .header {
        font-size: 10px;
        top: 0;
        left: 0;
        right: 0;
        height: 70px;
    }

    .headerRoutes {
        padding-left: 3px;
    }

    .headerRoutes>li {
        margin-right: 5px;
        text-align: center;
    }

}